@import "typography";
@import "base";

$navbar-height: 13.3rem;

body {
  font-family: $font-primary;
  overflow: hidden;
}

.home {
  display: flex;
  position: relative;
}

.error-message {
  color: $color-danger;
  font-size: 1.8rem;
}

.order {
  padding: 0 2rem;
  width: 50vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  &__body {
    position: relative;
    height: calc(100vh - #{$navbar-height});
    height: calc(calc(var(--vh, 1vh) * 100) - #{$navbar-height});
    overflow-y: auto;
    padding: 1rem;
  }

  &__title {
    font-size: 5rem;
    color: $color-primary;
    font-family: $font-secondary;
    font-weight: 400;
    font-style: italic;
  }

  &__subtitle {
    font-size: 4rem;
    color: $color-primary;
    font-family: $font-secondary;
    font-weight: 400;
    font-style: italic;
  }

  &__display-message {
    text-align: center;
    background-color: $color-bg-secondary;
    padding: 2rem;
    border-radius: 4px;
  }

  &__message-modal {
    position: absolute;
    width: calc(100% - 2rem);
    max-height: 100%;
    overflow-y: auto;
    top: 0;
  }

  &__message-title {
    font-size: 5rem;
    color: $color-text;
    line-height: 5rem;
    font-weight: 400;
  }

  &__message-subtitle {
    font-size: 4rem;
    color: $color-text;
    font-weight: 400;
    line-height: 4.4rem;
  }

  &__receipt-item {
    display: flex;
    color: $color-text;
    justify-content: space-between;
    margin-bottom: 1rem;
    font-size: 2.6rem;
    line-height: 2.6rem;

    p {
      margin-bottom: 0;
    }
  }

  &__receipt-item-quantity,
  &__receipt-item-name-container,
  &__receipt-item-price {
    flex: 1;
  }

  &__receipt-item-offer {
    margin-top: 0.5rem;
    font-size: 1.8rem;
    line-height: 1.8rem;
    color: rgba($color-text, 0.7);
  }

  &__receipt-item-quantity {
    text-align: center;
  }

  &__receipt-item-price {
    text-align: right;
  }

  &__receipt-total {
    display: flex;
    justify-content: space-between;
    font-size: 4rem;
    color: $color-primary;
    font-family: $font-secondary;
    font-weight: 400;
    font-style: italic;
  }

  &__customer-balance {
    font-size: 4rem;
    color: $color-text;
  }
}

.ads-area {
  max-width: 50vw;
  width: 100%;

  &__ad {
    object-fit: contain;
    object-position: left;
    height: 100%;
    width: 100%;
  }

  &__ad-container {
    overflow: hidden;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.login {
  padding: 5rem;
}

.login-form {
  width: 100%;
  max-width: 30rem;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 20rem;
  left: 50%;
  transform: translateX(-50%);

  &__title {
    font-size: 5rem;
    font-family: $font-secondary;
    font-style: italic;
    font-weight: 400;
    color: $color-primary;
    text-align: center;
    margin-bottom: 3rem;
  }

  &__input {
    width: 100%;
    height: 3rem;
  }

  &__submit {
    border: none;
    background-color: $color-primary;
    color: $color-bg;
    font-size: 2rem;
    margin-top: 3rem;
    padding: 0.5rem 1.5rem;
    align-self: center;
  }
}

.modal-title {
  font-size: 5rem;
  font-weight: 400;
  white-space: pre-wrap;
  color: $color-text;
  line-height: 5rem;

  &--success {
    color: $color-success;
  }

  &--danger {
    color: $color-danger;
  }
}

.modal-message {
  font-size: 4rem;
  font-weight: 400;
  white-space: pre-wrap;
  color: $color-text;
  line-height: 4.4rem;
  margin-top: 2rem;
}

.custom-modal {
  width: 50%;
  padding: 2rem;
  left: 50%;
  max-height: calc(100vh - 10.3rem);
  margin-top: 10.3rem;
}

.modal-body {
  padding: 3rem;
}

.modal-dialog {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  margin: 0;
}

.modal-content {
  background-color: $color-bg-secondary;
  border: none;
  height: 100%;
}

.data-table {
  color: $color-text;
  font-weight: 400;
  font-size: 2.6rem;

  thead {
    th {
      font-weight: 500;
      font-size: 2.8rem;
    }
  }

  tbody {
    td {
      color: $color-text  !important;
    }

    th {
      font-weight: 500;
      font-size: 2.8rem;
      color: $color-text  !important;
    }
  }
}

.show-order {
  &__title {
    color: $color-primary;
    font-size: 4rem;
    font-weight: 400;
    font-style: italic;
    font-family: $font-secondary;
  }

  &__customer {
    color: $color-text;
    font-size: 2.6rem;
    font-weight: 400;
  }
}

.bracelet-message {
  margin: 1rem 0;
  text-transform: uppercase;
}

.logo-container {
  width: 100%;
  margin-top: 3rem;
  margin-bottom: 3rem;
  text-align: right;
}

.logo {
  width: 20rem;
}

.promos {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;

  &__item-container {
    margin: 1rem 1rem 0 1rem;
    flex-basis: 12rem;
  }

  &__item-image {
    width: 100%;
  }

  &__item-name {
    text-align: center;
    color: $color-text;
    font-size: 2rem
  }
}

@import "responsive";
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap");
*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
  outline: none;
}

html {
  font-size: 10px;
  scroll-behavior: smooth;
}

body {
  background-color: #053a49;
  box-sizing: border-box;
}

button {
  cursor: pointer;
}

body {
  font-family: Source Sans Pro;
  overflow: hidden;
}

.home {
  display: flex;
  position: relative;
}

.error-message {
  color: red;
  font-size: 1.8rem;
}

.order {
  padding: 0 2rem;
  width: 50vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}
.order__body {
  position: relative;
  height: calc(100vh - 13.3rem);
  height: calc(calc(var(--vh, 1vh) * 100) - 13.3rem);
  overflow-y: auto;
  padding: 1rem;
}
.order__title {
  font-size: 5rem;
  color: #ffcd67;
  font-family: Source Sans Pro;
  font-weight: 400;
  font-style: italic;
}
.order__subtitle {
  font-size: 4rem;
  color: #ffcd67;
  font-family: Source Sans Pro;
  font-weight: 400;
  font-style: italic;
}
.order__display-message {
  text-align: center;
  background-color: #0f3443;
  padding: 2rem;
  border-radius: 4px;
}
.order__message-modal {
  position: absolute;
  width: calc(100% - 2rem);
  max-height: 100%;
  overflow-y: auto;
  top: 0;
}
.order__message-title {
  font-size: 5rem;
  color: #fff;
  line-height: 5rem;
  font-weight: 400;
}
.order__message-subtitle {
  font-size: 4rem;
  color: #fff;
  font-weight: 400;
  line-height: 4.4rem;
}
.order__receipt-item {
  display: flex;
  color: #fff;
  justify-content: space-between;
  margin-bottom: 1rem;
  font-size: 2.6rem;
  line-height: 2.6rem;
}
.order__receipt-item p {
  margin-bottom: 0;
}
.order__receipt-item-quantity, .order__receipt-item-name-container, .order__receipt-item-price {
  flex: 1;
}
.order__receipt-item-offer {
  margin-top: 0.5rem;
  font-size: 1.8rem;
  line-height: 1.8rem;
  color: rgba(255, 255, 255, 0.7);
}
.order__receipt-item-quantity {
  text-align: center;
}
.order__receipt-item-price {
  text-align: right;
}
.order__receipt-total {
  display: flex;
  justify-content: space-between;
  font-size: 4rem;
  color: #ffcd67;
  font-family: Source Sans Pro;
  font-weight: 400;
  font-style: italic;
}
.order__customer-balance {
  font-size: 4rem;
  color: #fff;
}

.ads-area {
  max-width: 50vw;
  width: 100%;
}
.ads-area__ad {
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: left;
     object-position: left;
  height: 100%;
  width: 100%;
}
.ads-area__ad-container {
  overflow: hidden;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.login {
  padding: 5rem;
}

.login-form {
  width: 100%;
  max-width: 30rem;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 20rem;
  left: 50%;
  transform: translateX(-50%);
}
.login-form__title {
  font-size: 5rem;
  font-family: Source Sans Pro;
  font-style: italic;
  font-weight: 400;
  color: #ffcd67;
  text-align: center;
  margin-bottom: 3rem;
}
.login-form__input {
  width: 100%;
  height: 3rem;
}
.login-form__submit {
  border: none;
  background-color: #ffcd67;
  color: #053a49;
  font-size: 2rem;
  margin-top: 3rem;
  padding: 0.5rem 1.5rem;
  align-self: center;
}

.modal-title {
  font-size: 5rem;
  font-weight: 400;
  white-space: pre-wrap;
  color: #fff;
  line-height: 5rem;
}
.modal-title--success {
  color: #1db954;
}
.modal-title--danger {
  color: red;
}

.modal-message {
  font-size: 4rem;
  font-weight: 400;
  white-space: pre-wrap;
  color: #fff;
  line-height: 4.4rem;
  margin-top: 2rem;
}

.custom-modal {
  width: 50%;
  padding: 2rem;
  left: 50%;
  max-height: calc(100vh - 10.3rem);
  margin-top: 10.3rem;
}

.modal-body {
  padding: 3rem;
}

.modal-dialog {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  margin: 0;
}

.modal-content {
  background-color: #0f3443;
  border: none;
  height: 100%;
}

.data-table {
  color: #fff;
  font-weight: 400;
  font-size: 2.6rem;
}
.data-table thead th {
  font-weight: 500;
  font-size: 2.8rem;
}
.data-table tbody td {
  color: #fff !important;
}
.data-table tbody th {
  font-weight: 500;
  font-size: 2.8rem;
  color: #fff !important;
}

.show-order__title {
  color: #ffcd67;
  font-size: 4rem;
  font-weight: 400;
  font-style: italic;
  font-family: Source Sans Pro;
}
.show-order__customer {
  color: #fff;
  font-size: 2.6rem;
  font-weight: 400;
}

.bracelet-message {
  margin: 1rem 0;
  text-transform: uppercase;
}

.logo-container {
  width: 100%;
  margin-top: 3rem;
  margin-bottom: 3rem;
  text-align: right;
}

.logo {
  width: 20rem;
}

.promos {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
}
.promos__item-container {
  margin: 1rem 1rem 0 1rem;
  flex-basis: 12rem;
}
.promos__item-image {
  width: 100%;
}
.promos__item-name {
  text-align: center;
  color: #fff;
  font-size: 2rem;
}

@media only screen and (max-width: 81.25em) {
  html {
    font-size: 8px;
  }
}/*# sourceMappingURL=styles.css.map */